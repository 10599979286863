.date-filter-input-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #b8c5d3;
  border-radius: 20px;
  height: 40px;
}

.date-filter-input-dates {
  /* width: 50%; */
  /* background-color: darkblue; */
}

.date-filter-input {
  background: #ffffff;
  border: none;
  border-radius: 20px;
  width: 100%;
  height: 35px;
  text-align: center;
  font-size: calc(8px + 0.5vw);
  color: rgba(37, 43, 72, 0.8);
}
