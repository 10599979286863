.vendor-card-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin: 10px 0;
  box-shadow: 0px 0px 20px rgba(215, 222, 227, 0.39422);
  border-radius: 5px;
}

.vendor-card-wrapper h5 {
  margin: 0;
}

.vendor-card-item-1 {
  min-width: 20%;
  min-height: 50px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.vendor-card-item-2 {
  width: 10%;
}

.vendor-card-item-3 {
  width: 30%;
}

.vendor-card-item-4 {
  width: 10%;
}

.vendor-card-item-5 {
  width: 10%;
}

.vendor-card-item-6 {
  width: 10%;
}
