.onboarding-page-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.onboarding-page-body-wrapper {
  flex: 1;
  width: 100%;
  display: flex;
}

.onboarding-page-body {
  position: relative;
  width: 75%;
  display: flex;
  flex-direction: column;
}

.onboarding-page-body-main {
  flex: 1;
  position: relative;
  overflow: auto;
}

.onboarding-page-body-main-fixed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: calc(20px + 2vw);
}

.onboarding-page-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.upload-csv-xl-file-details-header-row {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
}

.upload-csv-xl-file-footer-bottom-wrapper {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: #b8c5d3;
  /* height: 80px; */
  color: #ffffff;
  padding: 0 20px;
}

.upload-csv-xl-file-footer-bottom-wrapper > p {
  color: #ffffff;
  /* font-weight: bold; */
  font-size: calc(8px + 0.5vw);
  width: 100%;
  text-align: right;
  padding: 10px;
  background-color: #ff4b30;
}

.upload-csv-xl-file-footer-bottom-button-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
