.landing-page-wrapper {
  background-color: #ffffff;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-page-body {
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
}

.landing-page-body-left-section {
  width: 35%;
  background-color: #ffc34a;
  padding: calc(30px + 3vw) calc(10px + 1vw) calc(30px + 3vw) calc(30px + 3vw);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.landing-page-logo {
  cursor: pointer;
  width: 100px;
  height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.landing-page-body-left-section h1 {
  font-size: calc(20px + 2vw);
  font-weight: bold;
  margin: 5px 0 0 0;
}

.landing-page-body-left-section p {
  font-size: calc(10px + 1vw);
  color: #0f0f0f;
  margin: 0;
}

.landing-page-body-right-section {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 65%;
  max-height: 100%;
  padding: calc(20px + 2vw);
  overflow-y: scroll;
}
