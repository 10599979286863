.footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  background-color: #1f1f1f;
  color: #ffffff;
  padding: 0 calc(30px + 3vw);
  font-size: calc(6px + 0.3vw);
  z-index: 1000;
}
