.upload-csv-xl-wrapper {
  width: 100%;
}

.upload-csv-xl-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #b8c5d3;
  border-radius: 5px;
  min-height: 60px;
  padding: 20px;
  width: 500px;
}

.upload-csv-xl-upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-csv-xl-upload-btn-wrapper button {
  background: transparent;
  border: none;
  color: #ff4b30;
  font-weight: bold;
}

.upload-csv-xl-upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.upload-csv-xl-file-details-header {
  border-bottom: 1px solid #b8c5d3;
  padding-top: calc(30px + 3vw);
}

.upload-csv-xl-file-details-header-row {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
}

.upload-csv-xl-file-details-header-row p {
  font-size: calc(8px + 0.5vw);
  margin: 0;
  width: 100px;
}

.upload-csv-xl-file-details-header-row h4 {
  font-size: calc(10px + 0.5vw);
  margin: 0;
  flex: 1;
}

.upload-csv-xl-file-details-body {
  border: 1px solid #b8c5d3;
  padding: calc(30px + 3vw);
  position: relative;
  width: 100%;
}

.upload-csv-xl-file-details-body-row {
  display: flex;
  align-items: center;
  border: 1px solid #b8c5d3;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.xl-row-item1 {
  width: 50%;
  border-right: 1px solid #b8c5d3;
  padding: 20px;
}

.xl-row-item1 small {
  color: #555555;
  font-size: calc(8px + 0.5vw);
}

.xl-row-item1 div p {
  margin: 0;
  word-break: break-all;
}

.xl-row-item2 {
  width: 50%;
  padding: 20px;
}

.xl-row-header {
  background-color: #f2f2f2;
  text-align: center;
  font-size: calc(10px + 0.5vw);
  color: #5e5e5e;
  font-weight: bold;
}

.xl-row-header > p {
  margin: 0;
}

.upload-csv-xl-file-details-item-details {
  padding: 10px;
  border: 1px solid #f2f2f2;
  font-size: calc(8px + 0.5vw);
}

.upload-csv-xl-file-footer-bottom-wrapper {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: #b8c5d3;
  /* height: 80px; */
  color: #ffffff;
  padding: 0 20px;
}

.upload-csv-xl-file-footer-bottom-wrapper > p {
  color: #ffffff;
  /* font-weight: bold; */
  font-size: calc(8px + 0.5vw);
  width: 100%;
  text-align: right;
  padding: 10px;
  background-color: #ff4b30;
}

.upload-csv-xl-file-footer-bottom-button-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.upload-csv-xl-file-details-selected-category {
  font-size: calc(14px + 0.2vw);
  background-color: #ffffff;
  border: 1px solid #b8c5d3;
  border-radius: 20px;
  color: #1f1f1f;
  height: 40px;
  width: calc(50px + 20vw);
  padding: 0 calc(5px + 0.5vw);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.upload-csv-xl-file-details-selected-category-cancel-icon {
  border-radius: 100%;
  color: #ff4b30;
  cursor: pointer;
}
