.unmatched-fields-wrapper {
  width: 800px;
  background-color: #f2f2f2;
  padding: 30px;
  border-radius: 10px;
}

.unmatched-fields-wrapper > h1 {
  color: #ff4b30;
}

.unmatched-fields-details-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.unmatched-fields-details-row {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ffffff;
}

.unmatched-fields-details-row > h5 {
  margin: 0;
  width: 40%;
}

.unmatched-fields-details-row div {
  flex: 1;
}

.unmatched-fields-details-row p {
  margin: 0;
  color: #ff4b30;
}
