.dashboard-page-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.dashboard-page-body-wrapper {
  flex: 1;
  width: 100%;
  display: flex;
}

.dashboard-page-body {
  position: relative;
  width: 75%;
  display: flex;
  flex-direction: column;
}

.dashboard-page-body-main {
  flex: 1;
  position: relative;
  overflow: auto;
}

.dashboard-page-body-main-fixed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: calc(10px + 1vw) calc(10px + 1vw) 0 calc(10px + 1vw);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.dashboard-page-body-main-stats {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.dashboard-page-body-main-dashboard-details-body {
  position: relative;
  width: 100%;
  flex: 1;
}

.dashboard-page-body-main-dashboard-details-body-fixed {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.dashboard-page-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.dashboard-page-loader p {
  margin-top: 150px;
}

.dashboard-page-body-main-dashboard-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-page-body-main-dashboard-row div {
  width: 50%;
  min-height: 200px;
  padding: 10px;
  border: 1px solid #f2f2f2;
}
