.totals-card-wrapper {
  width: 45%;
  height: 200px;
  box-shadow: 0px 0px 20px rgba(215, 222, 227, 0.39422);
  border-radius: 10px;
  margin-bottom: calc(20px + 2vw);
  padding: calc(10px + 1vw);
}

.totals-card-wrapper h4 {
  margin: 0;
  color: #596675;
}

.totals-card-wrapper p {
  margin: 0;
  font-size: 11px;
}

.totals-card-wrapper h1 {
  text-align: center;
  margin-bottom: 0;
}

.totals-card-wrapper h3 {
  text-align: center;
  margin: 0;
  font-size: 8px;
  color: #0072d3;
}

.totals-card-linked-card {
  cursor: pointer;
}

.totals-card-linked-card:hover {
  background-color: #f2f2f2;
  color: #ff4b30;
  border: 1px solid #ff4b30;
}
