.item-card-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin: 50px 0;
  box-shadow: 0px 0px 20px rgba(215, 222, 227, 0.39422);
  border-radius: 5px;
}

.item-card-item-1 {
  width: 20%;
  border-radius: 5px;
  border: 1px solid #f2f2f2;
  padding: 10px;
}

.item-card-item-1 p,
.item-card-item-1 h5 {
  margin: 0;
  text-align: center;
}

.item-card-item-1 p {
  font-size: 12px;
}

.item-card-item-image {
  min-width: 100%;
  min-height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.item-card-item-2 {
  width: 30%;
}

.item-card-item-2 p {
  margin: 0;
  font-size: calc(5px + 0.5vw);
  color: #b8c5d3;
}

.item-card-item-3 {
  width: 20%;
}

.item-card-item-4 {
  width: 10%;
}

.item-card-alternate-error {
  font-size: 8px;
  color: #ff4e24;
}
