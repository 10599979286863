.overview-page-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.overview-page-body-wrapper {
  flex: 1;
  width: 100%;
  display: flex;
}

.overview-page-body {
  position: relative;
  width: 75%;
  display: flex;
  flex-direction: column;
}

.overview-page-body-main {
  flex: 1;
  position: relative;
  overflow: auto;
}

.overview-page-body-main-fixed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: calc(20px + 2vw);
}

.overview-page-body-main-filters {
  width: 100%;
  position: sticky;
  top: 0;
  padding: 5px 0;
  background-color: #ffffff;
}

.overview-page-body-main-stats {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: calc(20px + 2vw);
}

.overview-page-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.overview-page-loader p {
  margin-top: 150px;
}
