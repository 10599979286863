.filters-wrapper {
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 30px;
  border-bottom: 1px solid #f2f2f2;
}

.filters-wrapper p {
  margin: 0;
  font-size: 12px;
  padding: 5px 10px;
}

.filters-regions-filter {
  width: 30%;
}

.filters-date-filter {
  width: 40%;
  margin-left: 20px;
}

.filters-date-search {
  margin-left: 20px;
}

.filters-date-search-button {
  height: 40px;
  width: 40px;
  background-color: #f2f2f2;
  border-radius: 100%;
  padding: 5px;
  color: #ff4c30;
  cursor: pointer;
}

.filters-date-search-button:hover {
  background-color: #ff4c30;
  color: #ffffff;
}
