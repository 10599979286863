.navbar-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 calc(30px + 3vw);
  z-index: 1000;
  border-bottom: 1px solid rgba(184, 197, 211, 0.4);
}

.navbar-left-section h1 {
  margin: calc(5px + 1vw);
  font-size: calc(20px + 1vw);
}

.navbar-logo {
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: none;
}

.navbar-right-section {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
