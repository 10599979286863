.menu-card-wrapper {
  background: #ffffff;
  border-radius: 10px;
  min-width: 200px;
  max-width: 200px;
  min-height: 200px;
  border: 1px solid #b8c5d3;
  cursor: pointer;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
}

.menu-card-wrapper:hover {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
  background: #b8c5d3;
}

.menu-card-icon {
  width: 60px;
  height: 60px;
  color: #ff4c30;
}

.menu-card-wrapper:hover .menu-card-icon {
  color: #1f1f1f;
}

.menu-card-texts {
  text-align: center;
}

.menu-card-texts h3 {
  margin: 0;
}

.menu-card-texts p {
  margin: 0;
  font-size: 14px;
  color: #979797;
}

.menu-card-wrapper:hover .menu-card-texts p {
  color: #1f1f1f;
}
