.vendors-page-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.vendors-page-body-wrapper {
  flex: 1;
  width: 100%;
  display: flex;
}

.vendors-page-body {
  position: relative;
  width: 75%;
  display: flex;
  flex-direction: column;
}

.vendors-page-body-main {
  flex: 1;
  position: relative;
  overflow: auto;
}

.vendors-page-body-main-fixed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: calc(10px + 1vw) calc(10px + 1vw) 0 calc(10px + 1vw);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.vendors-page-body-main-filters {
  width: 100%;
  position: sticky;
  top: 0;
  padding: 5px 0;
  background-color: #ffffff;
  z-index: 1;
}

.vendors-page-body-main-stats {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.vendors-page-body-main-vendors-details-header {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.vendors-page-body-main-vendors-details-header h5 {
  margin: 0;
  word-wrap: break-word;
  font-size: calc(5px + 0.5vw);
  color: #b8c5d3;
}

.vendors-page-body-main-vendors-details-body {
  position: relative;
  width: 100%;
  flex: 1;
}

.vendors-page-body-main-vendors-details-body-fixed {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.header-item-1 {
  min-width: 20%;
}

.header-item-2 {
  width: 10%;
}

.header-item-3 {
  width: 30%;
}

.header-item-4 {
  width: 10%;
}

.header-item-5 {
  width: 10%;
}

.header-item-6 {
  width: 10%;
}

.vendors-page-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.vendors-page-loader p {
  margin-top: 150px;
}
