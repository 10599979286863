.items-page-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.items-page-body-wrapper {
  flex: 1;
  width: 100%;
  display: flex;
}

.items-page-body {
  position: relative;
  width: 75%;
  display: flex;
  flex-direction: column;
}

.items-page-body-main {
  flex: 1;
  position: relative;
  overflow: auto;
}

.items-page-body-main-fixed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: calc(10px + 1vw) calc(10px + 1vw) 0 calc(10px + 1vw);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.items-page-body-main-filters {
  width: 100%;
  position: sticky;
  top: 0;
  padding: 5px 0;
  background-color: #ffffff;
  z-index: 1;
}

.items-page-body-main-stats {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.items-page-body-main-items-details-body {
  position: relative;
  width: 100%;
  flex: 1;
}

.items-page-body-main-items-details-body-fixed {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.items-page-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.items-page-loader p {
  margin-top: 150px;
}

.items-page-body-pagination-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  border-top: 1px solid #b8c5d3;
}

.items-page-body-pagination-buttons p {
  font-size: calc(5px + 0.5vw);
  margin: 0;
}

.items-page-body-pagination-buttons p span {
  /* font-size: calc(10px + 0.5vw); */
  font-weight: bold;
}

.items-page-body-main-items-refresh {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
