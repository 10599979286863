.sidebar-wrapper {
  width: 25%;
  height: 100%;
  background-color: rgba(184, 197, 211, 0.2);
  padding: calc(5px + 1vw) 0 calc(5px + 1vw) calc(30px + 3vw);
}

.sidebar-wrapper h5 {
  margin: 0;
}

.sidebar-top {
  width: 100%;
  display: flex;
  align-items: center;
}

.sidebar-top h3 {
  font-size: calc(10px + 0.5vw);
  color: #ff4c30;
  margin-right: calc(30px + 3vw);
}

.sidebar-logo {
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 10px;
}

.sidebar-menus {
  padding-top: calc(30px + 3vw);
}
