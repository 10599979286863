.menu-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid #ffffff;
}

.menu-wrapper:hover {
  background: #ff4c30;
}

.menu-icon {
  width: 20px;
  height: 20px;
  color: #ff4c30;
  margin: 0 10px;
}

.menu-wrapper:hover .menu-icon {
  color: #1f1f1f;
}

.menu-wrapper h3 {
  font-size: calc(10px + 0.5vw);
  color: #1f1f1f;
  opacity: 0.5;
}

.menu-wrapper:hover h3 {
  opacity: 1;
}
